import React from 'react'
import { Head } from '~/components'
import Banner from '~/components/Banner'

import bannerSobre from '~/assets/img/banner-sobre.jpg'
import Newsletter from '~/components/Newsletter'
import Footer from '~/components/Footer'
import AboutHeader from '~/components/AboutHeader'
import Timeline from '~/components/Timeline'
import useScroll from '~/components/useScroll'
import Team from '~/components/Team'

const Sobre = () => {
  useScroll()

  return (
    <>
      <Head />
      <Banner
        image={bannerSobre}
        title="Sobre a Dimas Sempre"
        buttonText=""
        anchor=""
      />
      <AboutHeader />
      <Timeline />
      <Team />
      <Newsletter />
      <Footer />
    </>
  )
}

export default Sobre
