import React from 'react'

import * as st from '~/assets/styl/Team.module.styl'
import cn from 'classnames'
import { team } from '~/data'

export default function Team() {
  return (
    <div className={cn(st.core, 'page-width')}>
      <section>
        <h3>Conheça a nossa equipe</h3>
        <ul>
          {team.map(({ name, role, image }, index) => (
            <li key={index}>
              <div className={st.employee}>
                <img src={image} alt={name} />
                <h4>{name}</h4>
                <p>{role}</p>
              </div>
            </li> // End of li tag
          ))}
        </ul>
      </section>
    </div>
  )
}
