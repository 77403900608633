// extracted by mini-css-extract-plugin
export var description = "Timeline-module--description--MfdGR";
export var dimas = "Timeline-module--dimas--1d+n3";
export var gatsbyFocusWrapper = "Timeline-module--gatsby-focus-wrapper--U3YEP";
export var hideOnDesktop = "Timeline-module--hide-on-desktop--gbM8K";
export var hideOnMobile = "Timeline-module--hide-on-mobile--e-1IP";
export var iconCar = "Timeline-module--icon-car--2tCIv";
export var iconCars = "Timeline-module--icon-cars--ALa+U";
export var iconClock = "Timeline-module--icon-clock--ylsEO";
export var iconEletricCar = "Timeline-module--icon-eletric-car--7KNki";
export var iconFacebook = "Timeline-module--icon-facebook--gJ8HS";
export var iconGear = "Timeline-module--icon-gear--PlcMU";
export var iconInstagram = "Timeline-module--icon-instagram--Zqw4U";
export var iconKm = "Timeline-module--icon-km--0NpzR";
export var iconPhone = "Timeline-module--icon-phone--Wjr-3";
export var iconRight = "Timeline-module--icon-right--0zPkm";
export var iconWhatsapp = "Timeline-module--icon-whatsapp--2i4Jy";
export var imageWrapper = "Timeline-module--image-wrapper--Yrliw";
export var pageWidth = "Timeline-module--page-width--VsO3j";
export var path1 = "Timeline-module--path1--NkjjK";
export var path2 = "Timeline-module--path2--wYQ4g";
export var path3 = "Timeline-module--path3--r81LV";
export var timelineContainer = "Timeline-module--timeline-container--1zM3q";
export var view = "Timeline-module--view--xhFvp";
export var views = "Timeline-module--views--FyuMn";
export var year = "Timeline-module--year--M96ig";