// extracted by mini-css-extract-plugin
export var gatsbyFocusWrapper = "AboutHeader-module--gatsby-focus-wrapper--9MCfa";
export var headerContainer = "AboutHeader-module--header-container--Dp+FB";
export var hideOnDesktop = "AboutHeader-module--hide-on-desktop---gHo4";
export var hideOnMobile = "AboutHeader-module--hide-on-mobile--J+XUp";
export var iconCar = "AboutHeader-module--icon-car--cqEM0";
export var iconCars = "AboutHeader-module--icon-cars--6g864";
export var iconClock = "AboutHeader-module--icon-clock--tTWST";
export var iconEletricCar = "AboutHeader-module--icon-eletric-car--Mq8fF";
export var iconFacebook = "AboutHeader-module--icon-facebook--LKQ23";
export var iconGear = "AboutHeader-module--icon-gear--yu+0r";
export var iconInstagram = "AboutHeader-module--icon-instagram--+P9Rt";
export var iconKm = "AboutHeader-module--icon-km--qKtxk";
export var iconPhone = "AboutHeader-module--icon-phone--+xjht";
export var iconRight = "AboutHeader-module--icon-right--p1l9b";
export var iconWhatsapp = "AboutHeader-module--icon-whatsapp--KJ6p3";
export var pageWidth = "AboutHeader-module--page-width--uowar";
export var path1 = "AboutHeader-module--path1--ZOyBc";
export var path2 = "AboutHeader-module--path2--0C53F";
export var path3 = "AboutHeader-module--path3--xrnem";
export var view = "AboutHeader-module--view--3FwHR";
export var views = "AboutHeader-module--views--jGyMZ";