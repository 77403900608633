import React from 'react'

import cn from 'classnames'

import * as st from '~/assets/styl/AboutHeader.module.styl'

export default function AboutHeader() {
  return (
    <section id="quem-somos" className={cn(st.headerContainer, 'page-width')}>
      <h2>
        Fazemos parte das Empresas Dimas, sinônimo de qualidade e garantia.
      </h2>
      <p>
        A Dimas Sempre nasceu em 2021 sendo um novo pilar de atuação das
        empresas Dimas. Como objetivo buscamos oferecer soluções estratégicas e
        tecnológicas no segmento de assinatura veicular e terceirização de
        frotas.
      </p>
    </section>
  )
}
