import React from 'react'
import sobre from '~/assets/img/sobre.jpg'

import { timeLine } from '~/data'

import * as st from '~/assets/styl/Timeline.module.styl'
import Slider from '@fil1pe/react-slider'
import { LazyImage } from 'react-lazy-images'
import { useMediaQuery } from 'react-responsive'

export default function Timeline() {
  const isMobile = useMediaQuery({ query: '(max-width: 1080px)' })

  return (
    <div className={st.timelineContainer}>
      <div className={st.imageWrapper}>
        <LazyImage
          src={sobre}
          placeholder={({ ref }) => (
            <img
              ref={ref}
              src={sobre}
              alt="Várias mãos sobrepostas indicando um time"
            />
          )}
          actual={({ imageProps }) => <img {...imageProps} />}
        />
      </div>
      <section className={st.dimas}>
        <h2>História das Empresas Dimas</h2>
        <Slider
          adaptiveHeight={isMobile && true}
          renderArrow={(props) => <button {...props}></button>}
        >
          {timeLine.map((item) => (
            <>
              <p className={st.year}>Anos {item.year}</p>
              <p className={st.description}>
                {item.description} {item.bottom && <span>{item.bottom}</span>}
              </p>
            </>
          ))}
        </Slider>
      </section>
    </div>
  )
}
