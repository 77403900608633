// extracted by mini-css-extract-plugin
export var core = "Team-module--core--UGF9e";
export var gatsbyFocusWrapper = "Team-module--gatsby-focus-wrapper--0SQyF";
export var hideOnDesktop = "Team-module--hide-on-desktop--ydnzb";
export var hideOnMobile = "Team-module--hide-on-mobile--CGYMW";
export var iconCar = "Team-module--icon-car--0bB8k";
export var iconCars = "Team-module--icon-cars--x8bUO";
export var iconClock = "Team-module--icon-clock--7xYtn";
export var iconEletricCar = "Team-module--icon-eletric-car--v4Okg";
export var iconFacebook = "Team-module--icon-facebook--xmNU0";
export var iconGear = "Team-module--icon-gear--MmH+D";
export var iconInstagram = "Team-module--icon-instagram--8iTS4";
export var iconKm = "Team-module--icon-km--NRhM+";
export var iconPhone = "Team-module--icon-phone--vQxVU";
export var iconRight = "Team-module--icon-right--zyxBV";
export var iconWhatsapp = "Team-module--icon-whatsapp--cuLo4";
export var pageWidth = "Team-module--page-width--lmwNk";
export var path1 = "Team-module--path1--LUrfQ";
export var path2 = "Team-module--path2--LJkCl";
export var path3 = "Team-module--path3--CH95O";
export var view = "Team-module--view--LvY63";
export var views = "Team-module--views--pOM0u";